<template>
  <section class="page-container">
    <nav class="header d-flex justify-content-start">
      <div>
        <img src="../assets/pidging/logo.png" class="app-logo" alt="App logo">
      </div>
      <div class="header-text">
        <h2>Pidging</h2>
        <h6>Your pocket travel communication helper</h6>
      </div>
    </nav>
    <section class="d-flex justify-content-between col-12 main-content">
      <section class="description-content">
        <h2>Communicate with locals without language skills or Internet connection!</h2>
        <p>
          Pidging is a mobile app that helps travellers to communicate in local languages during the journey
          and works offline<sup>*</sup> without requiring constant Internet connection.
          No more confusing language gaps along the way!
        </p>
        <p>
          With a help of built-in vocabulary constructed from most useful simple words and phrases,
          and an ability to add your own collections of custom phrases, you'd be able to easily communicate through the
          various situations while travelling in a foreign country.
        </p>
        <h4>Features:</h4>
        <ul>
          <li>Curated collection of most useful words and phrases for travelling. The list is built based on the personal travelling experience and uses deliberately simple words to ease the communication.</li>
          <li>Translation to over 90 languages. Translation is supported by Microsoft Azure so the quality is really high.</li>
          <li>For supported languages, there's also translation transcriptions to help with pronunciation.</li>
          <li>Also for supported languages (the list is larger on Android than on iOS) there's text-to-speech pronunciation which helps to breach the communication barrier even more.</li>
          <li>Offline usage (with previously downloaded translations) - when the language is selected while the Internet connection is available, all the information is stored on the phone for future offline use.</li>
          <li>Creation of custom word/phrase collections to add new content that users require in their travels.</li>
          <li>Simple and fast interface for both iOS and Android</li>
        </ul>
        <h4>Why should I use it?</h4>
        <p>
          Pidging provides a helping hand in travel communications, when the Internet connection is slow or unavailable - but also when it’s there, as to translate something you’d still need to type it in and wait for a result. It’s a great reference vocabulary tool for the cases when you need simple but effective communication in a foreign language - could be to make your travelling experience more complete, but could also be to get through the journey in general as many crucial aspects of travel require verbal or written communication between people who don’t speak the same language.
        </p>
        <p>
          Pidging is simple, easy to use and allows building your own vocabulary for whatever cases you like. Translation in multiple languages, transcription and voice cues are all very helpful features to have available at your fingertips.
        </p>
        <h4>What does it cost?</h4>
        <p>Nothing! The app is completely free in both iOS AppStore and Android Play Store. It was built as a personal helper, and it should remain free with existing functionality.</p>
        <h4>Get it now for free!</h4>
        <div class="d-flex flex-wrap justify-content-around">
          <div class="store-block">
            <a href="https://apps.apple.com/app/id1557853007">
              <img src="../assets/app-store.png" alt="Apple App Store" class="store-icon"> From Apple App Store
            </a>
          </div>
          <div class="store-block">
            <a href="https://play.google.com/store/apps/details?id=net.hydralien.pidging">
              <img src="../assets/google-play.png" alt="Google Play Store" class="store-icon"> From Google Play Store
            </a>
          </div>
        </div>
      </section>
      <section class="slider">
        <VueSlickCarousel :arrows="true" :dots="true" v-bind="carouselSettings">
          <div><img src="../assets/pidging/screenshots/landing_page.png"></div>
          <div><img src="../assets/pidging/screenshots/built-in vocabulary.png"></div>
          <div><img src="../assets/pidging/screenshots/new collection.png"></div>
          <div><img src="../assets/pidging/screenshots/new idiom.png"></div>
          <div><img src="../assets/pidging/screenshots/languages.png"></div>
          <div><img src="../assets/pidging/screenshots/search.png"></div>
          <div><img src="../assets/pidging/screenshots/vocabulary sections.png"></div>

          <template #prevArrow="" class="prev-arrow">
            <div class="custom-arrow">
              Prev
            </div>
          </template>
          <template #nextArrow="" class="next-arrow">
            <div class="custom-arrow">
              Next
            </div>
          </template>
        </VueSlickCarousel>
      </section>
    </section>
    <footer class="d-flex justify-content-between footer">
      <div class="copyright">
        © <a href="https://hydralien.net">Boris Turchik</a>, 2021-2022
      </div>
      <div class="contacts">
        <span>Contact:</span>
        <a href="https://facebook.com/pidgingapp/">
          <font-awesome-icon :icon="['fab', 'facebook-square']"/>
        </a>
        <a href="mailto:hydra.public@gmail.com">
          <font-awesome-icon icon="envelope-square"/>
        </a>
      </div>
    </footer>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "PidgingPage",
  components: {
    VueSlickCarousel
  },
  metaInfo: {
    title: `Pidging - Travel Vocabulary App`,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: "iOS/Android Travel Vocabulary app that provides instant offline access to the most useful travel words and phrases, with ability to add your own collections."
      },
    ],
    link: [
      { rel: 'favicon', href: '/pidging/favicon.ico' }
    ]
  },
  data: () => {
    return {
      carouselSettings: {
        "dots": true,
        "arrows": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true
            }
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.app-logo {
  width: 70px;
  height: 70px;
  margin: 0 1rem;
}

.main-content {
  width: 100%;
  flex: 1 0 auto;
}

.description-content {
  display: inline-block;
  padding: 1rem 0 1rem 1rem;
}

.description-content h2 {
  margin-bottom: 1rem;
}

.description-content h4 {
  margin-top: 1.5rem;
}

.slider {
  max-width: 600px;
  display: inline-block;
  margin: 0 2rem;
  padding: 0 2rem;
}

.slider img {
  width: 250px;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.header {
  padding: 1rem;
}

.header h2 {
  color: rgb(38, 57, 119);
  margin: 0;
}

.header h6 {
  color: rgb(38, 99, 154);
}

.custom-arrow::before {
  color: darkslategray;
}

.header-text {
  padding: 5px 0;
}

.store-icon {
  width: 60px;
}

.footer {
  padding: 1rem 2rem;
  margin-top: 2rem;
  /*position: fixed;*/
  bottom: 0;
  width: 100vw;
  flex-shrink: 0;
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.contacts a {
  font-size: 2rem;
  margin: 0 0.3rem;
  vertical-align:middle
}

.copyright {
  padding: 0.5em 0;
}

p {
  text-indent: 1em;
}

@media (max-width: 576px) {
  .slider {
    width: 300px;
    display: block;
    margin: 0 1rem;
    padding: 0 1rem;
  }
  .description-content {
    display: block;
  }
  .main-content {
    flex-wrap: wrap;
  }
  .footer {
    padding: 1rem 1rem;
  }
}
</style>
